.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.thumbnail {
  width: calc(25% - 10px);
  max-height: 200px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.2s;
  margin: 20px 10px;
}

.thumbnail:hover {
  transform: scale(1.1);
}

/* Media Queries */
@media (max-width: 600px) {
  .thumbnail {
    width: 100%;
    margin: 5px 0;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .thumbnail {
    width: calc(50% - 10px);
    margin: 10px 0;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80vw;
  max-height: 80vh;
  background-color: rgb(255, 255, 255, 0);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: contain;
}

.enlarged {
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  /* padding: 10px 20px; */
  margin: 0px;
}

.close-button,
.prev-button,
.next-button {
  position: absolute;
  top: 10px;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10;
  font-size: 1.5rem;
}

.prev-button,
.next-button {
  top: 50%;
  transform: translateY(-50%);
  width: 2vw;
  font-size: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  justify-content: center;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

@media (max-width: 600px) {
  .prev-button,
  .next-button {
    width: 20px;
  }
}
